import axios from 'axios'
import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'


export const base_url=`https://ethio-exam-api.onrender.com`

export const getAllTeachers=createAsyncThunk('getAllTeachers',async()=>{
        try {
            const response=await axios.get(`${base_url}/teacher/getAllTeachersInfo`)
            return response.data
        } catch (error) {
          console.log(error)         
        }
})


const teacherSlice=createSlice({
    name:'teacher',
    initialState:{
        teachersData:[],
        isTeacherDataLoading:false,
        isTeacherInfoModalVisible:false,
        teacherDetailData:[],
        isTeacherMessageModalVisible:false,
    },
    reducers:{
    setTeacherDetailInfoModalVisible:(state,action) => {
      state.isTeacherInfoModalVisible=!state.isTeacherInfoModalVisible
    },
    setTeacherDetailData:(state,action) => {
      state.teacherDetailData=action.payload
    },
    setTeacherMessageModalVisible:(state,action)=>{
      state.isTeacherMessageModalVisible=!state.isTeacherMessageModalVisible
    }
    },
    extraReducers:{
        [getAllTeachers.pending]:(state,action)=>{
            state.isTeacherDataLoading=true
        },
        [getAllTeachers.fulfilled]:(state,action)=>{
            state.isTeacherDataLoading=false
            state.teachersData=action.payload
        },
        [getAllTeachers.pending]:(state,action)=>{
            state.isTeacherDataLoading=false
        },
    }
})

export const {
    setTeacherDetailInfoModalVisible,
    setTeacherDetailData,
    setTeacherMessageModalVisible

}=teacherSlice.actions

export const teacherReducer=teacherSlice.reducer