import axios from 'axios'
import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'




const librarySlice=createSlice({
    name:'books',
    initialState:{
    bookUrl:'',
  },
   reducers:{
      setBookUrl:(state,action)=>{
         state.bookUrl=action.payload
      }
   },

})

export const {setBookUrl}=librarySlice.actions

export const libraryReducer=librarySlice.reducer