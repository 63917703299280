import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { api, base_url } from "../../../config/api";


export const getExamById=createAsyncThunk('getExamById',async({id})=>{
    try {
          const response=await api.get(`${base_url}/teacher/getExam/${id}`)
          return response.data
    } catch (error) {
        console.log(error)
    }
})


const examSlice=createSlice({
    name:'examSlice',
    initialState:{
        examData:[],
        isExamLoading:false,
        examId:'',
        isExamModelVisible:false,
        examAnswer:[],
        score:0,
        isCheckingResult:false
    },
    reducers:{
       setExamId:(state,action)=>{
          state.examId=action.payload
       },
       setModelExamVisible:(state,action)=>{
          state.isExamModelVisible=action.payload
       },
       restExamData:(state,action)=>{
          state.examData=[]
          state.examAnswer=[]
          state.isCheckingResult=false
       },
       setExamAnswer:(state,action)=>{
        state.examData=action.payload
       },
       setScore:(state,action)=>{
        state.score=action.payload
       },
       setCheckingResult:(state,action)=>{
        state.isCheckingResult=action.payload
       }
    },
    extraReducers:{
        [getExamById.pending]:(state,action)=>{
           state.isExamLoading=true
        },
         [getExamById.fulfilled]:(state,action)=>{
           state.isExamLoading=false
           state.examData=action.payload
        },
         [getExamById.rejected]:(state,action)=>{
           state.isExamLoading=false
        }
    }
})

export const {
setExamId,
setModelExamVisible,
restExamData,
setExamAnswer,
setScore,
setCheckingResult
}=examSlice.actions
export const examReducer=examSlice.reducer

