import { createSlice } from "@reduxjs/toolkit";

const timeSlice=createSlice({
    name:'time',
    initialState:{
        second:60,
        minute:0
    },
    reducers:{
     setSecond:(state,action)=>{
         state.second=action.payload
     },
      setMinute:(state,action)=>{
         state.minute=action.payload
      },
      resetTime:(state,action)=>{
         state.minute=0
         state.second=60
      },
    }
})

export const {setMinute,setSecond,resetTime}=timeSlice.actions
export const timeReducer=timeSlice.reducer