import axios from 'axios'
import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'




const courseSlice=createSlice({
    name:'course',
    initialState:{
    courseData:[],
    sub_unitData:[],
    courseExamData:[],
    isVideoPlaying:false,
    videoDuration:0,
    courseListStyle:'grid',
    isTutorialModalVisible:false,
    tutorialVideoData:[]
  },
   reducers:{
      setCourseData:(state,action)=>{
         state.courseData=action.payload
      },
      setSubUnitData:(state,action)=>{
         state.sub_unitData=action.payload
      },
      setCourseExamData:(state,action)=>{
         state.courseExamData=action.payload
      },
       setVideoPlaying:(state,action)=>{
         state.isVideoPlaying=action.payload
      },
      setVideoDuration:(state,action)=>{
         state.videoDuration=action.payload
      },
      setCourseListStyle:(state,action)=>{
         state.courseListStyle=action.payload
      },
      setTutorialModalVisible:(state,action)=>{
         state.isTutorialModalVisible=!state.isTutorialModalVisible
      },
      setTutorialVideoData:(state,action)=>{
         state.tutorialVideoData=action.payload
      }
   },

})

export const {
   setCourseData,
   setSubUnitData,
   setCourseExamData,
   setVideoPlaying,
   setVideoDuration,
   setCourseListStyle,
   setTutorialModalVisible,
   setTutorialVideoData
}=courseSlice.actions

export const courseReducer=courseSlice.reducer