import React,{lazy} from 'react'
import {Routes,Route} from 'react-router-dom'
import PrivateRoute from '../privateRoute/PrivateRoute'
import { MathJaxContext } from 'better-react-mathjax'

const SignIn=lazy(() => import('../auth/pages/SignIn'))
const SignUp=lazy(() => import('../auth/pages/SignUp'))
const PrivacyPolicy=lazy(() => import('../auth/pages/PrivacyPolicy'))
const Home = lazy(() => import('../pages/Home'))
const About = lazy(() => import('../pages/AboutUs'))
const FeedBack = lazy(() => import('../pages/FeedBack'))
const ExamHomePage = lazy(() => import('../pages/Exam/ExamHomePage'))
const ExamPage = lazy(() => import('../pages/Exam/ExamPage'))
const LibraryHome = lazy(() => import('../pages/library/pages/LibraryHome'))
const ProfileHomePage = lazy(() => import('../pages/personal_profile/pages/ProfileHomePage'))
const Dashboard = lazy(() => import('../pages/personal_profile/pages/Dashboard'))
const ViewBook = lazy(() => import('../pages/library/pages/ViewBook'))
const MyExamList = lazy(() => import('../pages/personal_profile/pages/MyExamList'))
const MyNote = lazy(() => import('../pages/personal_profile/pages/MyNote'))
const ResultPage = lazy(() => import('../pages/Exam/ResultPage'))
const NotesPage= lazy(()=>import('../pages/NotesPage'))
const Tutorial = lazy(() => import('../pages/Tutorial'))
const Wallet = lazy(() => import('../pages/personal_profile/pages/Wallet'))
const Setting = lazy(() => import('../pages/personal_profile/pages/Setting'))
const Calender = lazy(() => import('../pages/personal_profile/pages/Calender'))
const MyProgress = lazy(() => import('../pages/personal_profile/pages/MyProgress'))
const Course = lazy(() => import('../pages/courses/pages/Course'))
const Courses = lazy(() => import('../pages/personal_profile/pages/Courses'))
const Teachers = lazy(() => import('../pages/personal_profile/pages/Teachers'))
const CoursesHomePage = lazy(() => import('../pages/courses/pages/CoursesHomePage'))
const PageIsNotFound =lazy (()=>import('../shared/components/PageIsNotFound'))
const SendToEmail =lazy (()=>import('../auth/pages/SendToEmail'))
const ForgotPassword =lazy (()=>import('../auth/pages/ForgotPassword'))
const PhysicsHomePage =lazy (()=>import('../pages/simulations/physics/pages/PhysicsHomePage'))
const SimulationHomePage =lazy (()=>import('../pages/simulations/SimulationHomePage'))
const MathsSimulationHomePage =lazy (()=>import('../pages/simulations/maths/pages/MathsSimulationHomePage'))
const ChemistryHomePage =lazy (()=>import('../pages/simulations/chemistry/pages/ChemistryHomePage'))


const MainRoute = () => {


  return (
    <MathJaxContext>
  <Routes>
        <Route path='/' element={<Home/>}/>
        <Route element={<PrivateRoute/>}>
          <Route  path='/exam' element={<ExamPage/>}/>
          <Route path='/exam/:id' element={<ExamPage/>}/>
          <Route path='/tutorial' element={<Tutorial/>}/>
          <Route path='/exam_home_page' element={<ExamHomePage/>}/>
          <Route path='/result' element={<ResultPage/>}/>
          <Route path='/note' element={<NotesPage/>}/>
          <Route path='/course_home_page' element={<CoursesHomePage/>}/>
          <Route path='/course' element={<Course/>}/>
          <Route path='/simulation' element={<SimulationHomePage/>}/>
          <Route path='/simulation/maths' element={<MathsSimulationHomePage/>}/>
          <Route path='/simulation/physics' element={<PhysicsHomePage/>}/> 
          <Route path='/simulation/chemistry' element={<ChemistryHomePage/>}/>  
        
        <Route path='/personal'  element={<ProfileHomePage/>}>
          <Route path='' element={<Dashboard/>}/>
          <Route path='calender' element={<Calender/>}/>
          <Route path='myExam' element={<MyExamList/>}/>
          <Route path='myNote' element={<MyNote/>}/>
          <Route path='myProgress' element={<MyProgress/>}/>
          <Route path='setting' element={<Setting/>}/>
          <Route path='wallet' element={<Wallet/>}/>
          <Route path='teachers' element={<Teachers/>}/>
          <Route path='courses' element={<Courses/>}/>
        </Route>
        </Route> 
        <Route path='/viewbook' element={<ViewBook page={0}/>}/>
        <Route path='/signIn' element={<SignIn/>}/>
        <Route path='/signUp' element={<SignUp/>}/>
        <Route path='/policy' element={<PrivacyPolicy/>}/>
        <Route path='/restLink' element={<SendToEmail/>}/>
        <Route path='/rest_password/:id' element={<ForgotPassword/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/feedback' element={<FeedBack/>}/>
        <Route path='/library'  element={<LibraryHome />}/>
       
        <Route  
          path='/*' 
          element={<PageIsNotFound/>}
          />
      </Routes>
      </MathJaxContext>
  )
}

export default MainRoute