import axios from 'axios'
import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'


export const base_url=`https://ethio-exam-api.onrender.com`

export const saveAnswerExam=createAsyncThunk('saveAnswerExam',async({question,toast})=>{
        try {
            const response=await axios.post(`${base_url}/saveAnsweredExam`,question)
            toast.success("Your Score saved successfully check your dashboard")
            return response.data
        } catch (error) {
          console.log(error)         
        }
})


export const getAnsweredExam=createAsyncThunk('getAnsweredExam',async({student_id})=>{
        try {
            const response=await axios.get(`${base_url}/getStudentAnswer/${student_id}`)
            return response.data
        } catch (error) {
          console.log(error)         
        }
})


export const deleteAnswer=createAsyncThunk('deleteAnswer',async({exam_id,toast})=>{
        try {
            const response=await axios.delete(`${base_url}/deleteAnsweredExam/${exam_id}`)
             toast.success('your Answer successfully deleted')
            return response.data
        } catch (error) {
          toast.error(error?.response?.data?.message)             
        }
})


const answerSlice=createSlice({
    name:'answer',
    initialState:{
    isUserLoggedIn:false,
    answers:[],
    examAnswer:[],
    correctAnswerCount:0
  },
  reducers:{
    setAnswer(state,action){
      state.answers=action.payload
    },
    setCorrectAnswerCount(state,action){
      state.correctAnswerCount=action.payload
    }
  },
   extraReducers:{
    [getAnsweredExam.pending]:(state,action)=>{
      state.isUserLoggedIn=false
    },
    [getAnsweredExam.fulfilled]:(state,action)=>{
      state.isUserLoggedIn=true
      state.examAnswer=action.payload  
    },
    [getAnsweredExam.rejected]:(state,action)=>{
      state.isUserLoggedIn=false
    },
    [deleteAnswer.pending]:(state,action)=>{
      state.isUserLoggedIn=false
    },
    [deleteAnswer.fulfilled]:(state,action)=>{
      return {
        ...state,studentNote:state.examAnswer.filter(data=>data.id!==action.payload)
      }
    },
    [deleteAnswer.rejected]:(state,action)=>{
      state.isUserLoggedIn=false
    },
  }
})

export const {setAnswer,setCorrectAnswerCount}=answerSlice.actions

export const answerReducer=answerSlice.reducer