import axios from 'axios'
import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'


export const base_url=`https://ethio-exam-api.onrender.com`

export const getAllQuestions=createAsyncThunk('getAllQuestions',async()=>{
        try {
            const response=await axios.get(`${base_url}/getAllQuestions`)
            return response.data
        } catch (error) {
           console.log(error)            
        }
})


const questionsSlice=createSlice({
    name:'questions',
    initialState:{
    questions:[],
    examQuestions:[],
    checked:'',
    userAnswer:[],
    numberOfAnsweredQuestion:0,
    second:60,
    minute:0,
    isExamCompleted:false,
    examNumberSelected:false,
    numberOfQuestion:0
  },
   reducers:{
      setExamQuestions:(state,action)=>{
         state.examQuestions=action.payload
      },
      setChecked:(state,action)=>{
         state.checked=action.payload
      },
      setAnswer:(state,action)=>{
         state.userAnswer=action.payload
      },
      setAnsweredLength:(state,action)=>{
         state.numberOfAnsweredQuestion+=action.payload
      },

       restExamState:(state,action)=>{
         state.examQuestions=[]
         state.numberOfAnsweredQuestion=0
         state.checked=''
         state.userAnswer=[]
         state.isExamCompleted=false
         state.examNumberSelected=false
          state.numberOfQuestion=0
      },
       submitExam:(state,action)=>{
         state.second=0
         state.minute=0
         state.numberOfAnsweredQuestion=0
         state.checked=''
         state.userAnswer=[]
         state.isExamCompleted=false
         state.examNumberSelected=false
          state.numberOfQuestion=0
      },
      setNumberQuestionToView:(state,action)=>{
          state.examNumberSelected=true
          state.numberOfQuestion=action.payload
      }


   },
  extraReducers:{
    [getAllQuestions.pending]:(state,action)=>{
      state.isUserLoggedIn=false
    },
    [getAllQuestions.fulfilled]:(state,action)=>{
      state.isUserLoggedIn=true
      state.questions=action.payload
     
    },
    [getAllQuestions.rejected]:(state,action)=>{
      state.isUserLoggedIn=false
    }
  }
})

export const {
  setExamQuestions,
  setChecked,
  setAnswer,
  setAnsweredLength,
  restExamState,
  setNumberQuestionToView,
  submitExam
}=questionsSlice.actions

export const questionsReducer=questionsSlice.reducer