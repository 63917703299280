import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { studentReducer } from "./features/userSlice";
import { answerReducer } from "./features/answer";
import { questionsReducer } from "./features/questions";
import { noteReducer} from "./features/noteSlice";
import { libraryReducer } from "./features/library";
import { feedbackReducer } from "./features/feedback";
import { courseReducer } from "./features/course";
import { dashboardReducer } from "./features/dasboard";
import { resistorReducer } from "../pages/simulations/physics/redux/resistor_slice";
import { colorReducer } from "../pages/personal_profile/redux/colorSlice";
import { profileReducer } from "../pages/personal_profile/redux/profileSlice";
import { teacherReducer } from "./features/teacher";
import { calenderReducer } from "./features/calenderSlice";
import { geminiReducer } from "./features/geminiSlice";
import { timeReducer } from "./features/timerSlice";
import { examReducer } from "../pages/Exam/redux/exam_slice";
import { globalReducer } from "./features/globalSlice";


const persistConfig = {
  key: "persistCollection",
  storage,
};

const reducer = combineReducers({
    questions:questionsReducer,
    student:studentReducer,
    answer:answerReducer,
    feedback:feedbackReducer,
    note:noteReducer,
    library:libraryReducer,
    course:courseReducer,
    dashboard:dashboardReducer,
    resistor:resistorReducer,
    color:colorReducer,
    profile:profileReducer,
    teacher:teacherReducer,
    calender:calenderReducer,
    gemini:geminiReducer,
    time:timeReducer,
    exam:examReducer,
    global:globalReducer

});


const persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
