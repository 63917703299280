import { createSlice } from "@reduxjs/toolkit";

const colorSlice=createSlice({
    name:'colors',
    initialState:{
        isBackgroundDark:false,
    },
    reducers:{
      setDarkMode:(state,action)=>{
         state.isBackgroundDark=!state.isBackgroundDark
    }
    }
    
})

export const {setDarkMode}=colorSlice.actions

export const colorReducer=colorSlice.reducer