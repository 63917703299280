import axios from 'axios'
import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'


export const base_url=`https://ethio-exam-api.onrender.com`

export const createFeedBack=createAsyncThunk('createFeedBack',async({feedBackData,toast})=>{
        try {
            const response=await axios.post(`${base_url}/createFeedback`,feedBackData)
            toast.success('Successfully sent')
            return response.data
        } catch (error) {
           toast.error(error?.response?.data?.message)         
        }
})

export const getFeedBacks=createAsyncThunk('getFeedBacks',async()=>{
        try {
            const response=await axios.get(`${base_url}/getFeedbacks`)
            return response.data
        } catch (error) {
           console.log(error)            
        }
})


const feedbackSlice=createSlice({
    name:'feedback',
    initialState:{
    feedbackSent:false,
  },
  extraReducers:{
    [createFeedBack.pending]:(state,action)=>{
      state.feedbackSent=false
    },
    [createFeedBack.fulfilled]:(state,action)=>{
      state.feedbackSent=true
    },
    [createFeedBack.rejected]:(state,action)=>{
      state.feedbackSent=false
    }
  }
})

// export const {setExamQuestions}=questionsSlice.actions

export const feedbackReducer=feedbackSlice.reducer