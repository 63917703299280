import {createSlice} from '@reduxjs/toolkit'
import { fourBandResistor } from '../../utils/value_to_color'


const resistorSlice=createSlice({
    name:'resistor',
    initialState:{
        resistanceValue:0,
        bandValue:{
            firstBand:'black',
            secondBand:'black',
            thirdBand:'black',
            fourthBand:'gold'
        },
        reducers:{
            setResistanceValue:(state,action)=>{
               state.resistanceValue=action.payload  
            },
      setBandData:(state,action)=>{
        state.bandValue.firstBand=fourBandResistor(state.resistanceValue)?.firstBand
        state.bandValue.secondBand=fourBandResistor(state.resistanceValue)?.secondBand
        state.bandValue.thirdBand=fourBandResistor(state.resistanceValue)?.thirdBand
        state.bandValue.fourthBand= fourBandResistor(state.resistanceValue)?.fourthBand
    }
    }
    }
})

export const {setResistanceValue}=resistorSlice.actions
export const resistorReducer=resistorSlice.reducer