import axios from 'axios'
import {createSlice} from '@reduxjs/toolkit'

const dashboardSlice=createSlice({
    name:'course',
    initialState:{
    progressData:[],
    subject:'All',
    isVideoPlaying:false,
    videoDuration:0
  },
   reducers:{
      setProgressData:(state,action)=>{
         state.progressData=action.payload
      },
      setSubject:(state,action)=>{
         state.subject=action.payload
      },
      setCourseExamData:(state,action)=>{
         state.courseExamData=action.payload
      },
       setVideoPlaying:(state,action)=>{
         state.isVideoPlaying=action.payload
      },
      setVideoDuration:(state,action)=>{
         state.videoDuration=action.payload
      }
   },

})

export const {
   setProgressData,
   setSubject,
   setCourseExamData,
   setVideoPlaying,
   setVideoDuration
}=dashboardSlice.actions

export const dashboardReducer=dashboardSlice.reducer