import { GoogleGenerativeAI } from "@google/generative-ai";
import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";



const genAI = new GoogleGenerativeAI(process.env.REACT_APP_API_KEY);
const model = genAI.getGenerativeModel({ model: "gemini-pro"});

export const geminiAI=createAsyncThunk('geminiAI',async({prompt})=>{
  
   try {
      if(prompt!==''){
       const chat = model.startChat({
    history: [
      {
        role: "user",
        parts: [{ text: "Hello, I want to learn." }],
      },
      {
        role: "model",
        parts: [{ text: "Great to meet you. What would you like to know?" }],
      },
    ],
    // generationConfig: {
    //   maxOutputTokens: 100,
    // },
  });

  const result = await chat.sendMessage(prompt);
  const response = await result.response;
  const text = response.text();
       return  text
      }else console.log({message:"Please insert text"})
      
  } catch (error) {
    console.log(error)
  }
})


const geminiSlice=createSlice({
    name:'gemini',
    initialState:{
        geminiResponseData:[],
        isGeminiModalVisible:false,
        isLoadingGeminiMessage:false
    },
    reducers:{
        setGeminiModalVisible:(state,action)=>{
            state.isGeminiModalVisible=!state.isGeminiModalVisible
        }
    },
    extraReducers:{
        [geminiAI.pending]:(state,action)=>{
            state.geminiResponseData=[]
            state.isLoadingGeminiMessage=true
        },
        [geminiAI.fulfilled]:(state,action)=>{
            state.geminiResponseData=action.payload
            state.isLoadingGeminiMessage=false
        },
        [geminiAI.pending]:(state,action)=>{
            state.geminiResponseData=[]
            state.isLoadingGeminiMessage=false
        },
    }
})

export const {setGeminiModalVisible}=geminiSlice.actions
export const geminiReducer=geminiSlice.reducer
