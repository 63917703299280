import { createSlice} from "@reduxjs/toolkit";


const calenderSlice=createSlice({
    name:'calender',
    initialState:{
        currentEvent:[]
    },
    reducers:{
        setCurrentEvent:(state,action)=>{
            state.currentEvent=action.payload
        }
    }
})

export const {setCurrentEvent}=calenderSlice.actions
export const calenderReducer=calenderSlice.reducer