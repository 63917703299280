import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { noteData } from "../../assets/data/note_Initial_data";

export const base_url=`https://ethio-exam-api.onrender.com`



export const createNote=createAsyncThunk('createNote',async({newNoteData,toast})=>{
    try {
            await axios.post(`${base_url}/newNote`,newNoteData)
            toast.success('Your note successfully created')
    } catch (error) {
        console.log(error)
    }
})


export const updateNote=createAsyncThunk('updateNote',async({id,noteData,toast})=>{
    try {
       const response= await axios.put(`${base_url}/update_note/${id}`,noteData)
       toast.success('Your note successfully updated')
       return response.data
    } catch (error) {
        console.log(error)
    }
})

export const deleteNote=createAsyncThunk('deleteNote',async({id,toast})=>{
    try {
       await axios.delete(`${base_url}/delete_note/${id}`)
       toast.success('Your note successfully deleted')
    } catch (error) {
        console.log(error)
    }
})

export const getStudentNotes=createAsyncThunk('getStudentNotes',async({student_id})=>{
    try {
       const response= await axios.get(`${base_url}/get_student_note/${student_id}`)
       return response.data
    } catch (error) {
        console.log(error)
    }
})

export const getSharedNoteList=createAsyncThunk('getSharedNoteList',async()=>{
        try {
            const response=await axios.get(`${base_url}/getSharedNote`)
            console.log(response.data)
            return response.data
        } catch (error) {
          console.log(error?.response?.data?.message)        
        }
})

export const createSharedNote=createAsyncThunk('createSharedNote',async({sharedData,toast})=>{
        try {
            const response=await axios.post(`${base_url}/share_note`,sharedData)
            toast.success('your note successfully added')
            return response.data
        } catch (error) {
         toast.error(error?.response?.data?.message)             
        }
})




const noteSlice=createSlice({
    name:'note',
    initialState:{
        isNoteModalVisible:false,
        noteData:noteData,
        studentNotes:[],
        sharedNotes:[],
        isNoteLoading:false,
        isNoteDeleted:false,
        isNoteEditing:false,
        isNoteCreated:false,
        isNoteEdited:false,
        isPreviewNoteModalClicked:false,
        previewData:[],
      
    },
    reducers:{
        setNoteModalVisible:(state,action)=>{
            state.isNoteModalVisible=!state.isNoteModalVisible
        },
        setNoteData:(state,action)=>{
            return{
                ...state,
                noteData:{
                     ...state.noteData,
                     ...action.payload 
                }
            }
           
        },
         restNoteData:(state,action)=>{
            state.noteData=noteData
            state.isNoteEditing=false
       },
        setNoteColor:(state,action)=>{
            return{
                ...state,
                noteData:{
                    ...state.noteData,
                    bgColor:action.payload
                }
            }
        },
        setNoteEditingBoolean:(state,action)=>{
            state.isNoteEditing=!state.isNoteEditing
             state.isNoteModalVisible=!state.isNoteModalVisible
        },
        setNoteEditingData:(state,action)=>{
            state.noteData=action.payload
        },
        setPreviewNoteModal:(state,action)=>{
            state.isPreviewNoteModalClicked=!state.isPreviewNoteModalClicked
        },
        setPreviewData:(state,action)=>{
            state.previewData=action.payload
        } 
       
    },
    extraReducers:{
        [getStudentNotes.pending]:(state,action)=>{
            state.isNoteLoading=true
        },
        [getStudentNotes.fulfilled]:(state,action)=>{
            state.isNoteLoading=false
            state.studentNotes=action.payload
        },
        [getStudentNotes.rejected]:(state,action)=>{
            state.isNoteLoading=false
        },
        [createNote.pending]:(state,action)=>{
            state.isNoteCreated=false
        },
        [createNote.fulfilled]:(state,action)=>{
            state.isNoteCreated=true
        },
        [createNote.rejected]:(state,action)=>{
            state.isNoteCreated=false
        },
        [updateNote.pending]:(state,action)=>{
            state.isNoteEdited=false
        },
        [updateNote.fulfilled]:(state,action)=>{
            state.isNoteEdited=true
        },
        [updateNote.rejected]:(state,action)=>{
            state.isNoteEdited=false
        },
        [deleteNote.pending]:(state,action)=>{
            state.isNoteDeleted=false
        },
        [deleteNote.fulfilled]:(state,action)=>{
            state.isNoteDeleted=true
        },
        [deleteNote.rejected]:(state,action)=>{
            state.isNoteDeleted=false
        },
        [getSharedNoteList.pending]:(state,action)=>{
            state.sharedNotes=[]
        },
        [getSharedNoteList.fulfilled]:(state,action)=>{
            state.sharedNotes=action.payload
        },
        [getSharedNoteList.rejected]:(state,action)=>{
            state.sharedNotes=[]
        },
    }
})
export const {
setNoteModalVisible,
setNoteColor,
setNoteData,
restNoteData,
setNoteEditingData,
setNoteEditingBoolean,
setPreviewNoteModal,
setPreviewData
}=noteSlice.actions
export const noteReducer=noteSlice.reducer