
import './App.css'
import {BrowserRouter as Router} from 'react-router-dom'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Suspense } from 'react'
import LoadingPage from './shared/components/LoadingPage'
import MainRoute from './Route/MainRoute';
import { useEffect } from 'react';
import ScrollToTop from './Route/ScrollToTop';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

const telegramWeb=window?.Telegram?.WebApp 
function App() {
  const {isBackgroundDark}=useSelector(state=>state.color) 
   useEffect(()=>{
    if(telegramWeb!==undefined) telegramWeb.ready()
   },[])

  useEffect(() => {
  window.scrollTo(0, 0);
}, []);
  
  return (
    
    <div 
    className='app' 
    style={{backgroundColor:!isBackgroundDark?'#0A192F':'white'}}>
      <ToastContainer 
        autoClose={500} 
        style={{zIndex:300000000}}   
        theme={!isBackgroundDark?'dark':'light'}
      />
    <Router>
    
      <Suspense fallback={<LoadingPage/>}>
        <ScrollToTop/>
          <MainRoute/>
      </Suspense>
    </Router>
    </div>

  )
}

export default App
