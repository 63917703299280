import { createSlice } from "@reduxjs/toolkit";

const profileSlice=createSlice({
    name:'profile',
    initialState:{
        isProfileUploaded:false,
        profileImage:[]
    },
    reducers:{
      setProfileImage:(state,action)=>{
         state.profileImage=action.payload
    }
    }
    
})

export const {setProfileImage}=profileSlice.actions

export const profileReducer=profileSlice.reducer