import React from 'react'
import {Box} from '@mui/material'
import loadingImage from '../../assets/Images/loading.gif'
import { useSelector } from 'react-redux'
const LoadingPage = () => {
  const {isBackgroundDark}=useSelector(state=>state.color)
  return (
    <div className='w-full h-[100vh] flex justify-center items-center bg-[#0A192F]' 
    style={{backgroundColor:!isBackgroundDark?"#0A192F":"white"}}>
      <img className='w-[120px] h-[120px] rounded-full' src={loadingImage} alt="logo" />
    </div>
  )
}


export default LoadingPage