let color=['black','brown','red','orange','yellow','green','blue','violet','grey']

export const fourBandResistor=(value)=>{
    
   if(value?.length===1&&value==='') return {
    firstBand:'black',
    secondBand:'black',
    thirdBand:'black'
}
   else return{     
    firstBand:color[value[0]],
    secondBand:color[value[1]?value[1]:0],
    thirdBand:color[value?.length-2?value?.length-2:0]
}
   
}