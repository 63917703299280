import { createSlice } from "@reduxjs/toolkit";


const globalSlice=createSlice({
    name:'global',
    initialState:{
        isSideBarVisible:false
    },
    reducers:{
        setSideBarVisible:(state,action)=>{
            state.isSideBarVisible=action.payload
        }
    }
})

export const {setSideBarVisible}=globalSlice.actions
export const globalReducer=globalSlice.reducer